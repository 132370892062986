
import { getDictFLJ,
         getDictWP } from '~/utils/butterUtils'
import { mapMutations, mapGetters, mapActions, mapState } from "vuex"
import fieldLabelAndTooltip from '~/mixins/fieldLabelAndTooltip.js'
import { placesAutocomplete,
         placeDetails,
         uuidv4,
         cleanString } from "~/utils/helpers.js"

export default {
  name: "AbroadAddress",
  mixins: [fieldLabelAndTooltip],
  props: [
    "v",
    "placeholder",
    "fieldName",
    "instructions",
    "label",
    "toolTipTitle",
    "toolTipContent",
    "maxlengthFields",
    "dict"
  ],
  data() {
    return {
      tempA: "",
      countryFields: [
        {
          help: "",
          type: "countryiso",
          label: "Country",
          required: true,
          length: 1,
        },
      ],
      data: [],
      sessionToken: "",
      isFetching: false,
      autocompleteFocused: false,
      needsFormat: false,
      hasFocus: "",
    };
  },
  computed: {
    showTestData () {
      return this.$store.state.showTestData
    },
    showCodeFragmentMark() {
      return this.$store.state.showCodeFragmentMark;
    },
    formattedAddress() {
      if (this.adr && this.adr.usesAlternateFormat) {
        return ["alt1", "alt2", "alt3", "alt4"]
          .map((x) => this.adr[x] || null)
          .filter((x) => x)
          .concat(this.countryData.name);
      } else {

        return this.countryData && this.countryData.cfmt
          ? this.countryData.cfmt
              .replace(
                /%([N|O|A|B|D|C|S|Z|X])/g,
                (match, p1, offset, string) => this.adr[p1] || ""
              )
              .split(/%n/g)
              .filter((x) => x)
              .concat(this.countryData.name)
          : [""];
      }
    },
    ctry() {
      return this.adr && this.adr.countryiso ? this.adr.countryiso : "";
    },
    usesAlternateFormat() {
      return this.adr && this.adr.usesAlternateFormat
        ? this.adr.usesAlternateFormat
        : false;
    },
    countryData() {
      /**
       * 2024-05-23 John Yee
       * for US armed forces ZIP codes i.e. APO, FPO see https://apps.naaccr.org/vpr-cls/about-postal-codes
       * I don't know how up-to-date this list is.
       * 
       * Other searches on Google do not show the ZIP codes 96860 or 96863 listed among the military ZIP codes.
       */
      if (this.ctry && this.postalMetadataHasCountry(this.ctry)) {
        return this.ctry === "US" && this.fieldName === "abrAdr"
          ? {
              fmt: "%N%n%O%n%A%n%C, %S %Z",
              id: "data/US",
              locality_name_type: "city",
              require: "ACSZ",
              state_name_type: "state",
              sublocality_name_type: "suburb",
              upper: "CS",
              zip_name_type: "zip",
              key: "US",
              lang: "en",
              languages: "en",
              name: "UNITED STATES",
              posturl: "https://tools.usps.com/go/ZipLookupAction!input.action",
              sub_isoids: "AA~AE~AP",
              sub_keys: "AA~AE~AP",
              sub_names:
                "Armed Forces (AA)~Armed Forces (AE)~Armed Forces (AP)",
              sub_zipexs: "34000,34099~09000,09999~96200,96699",
              sub_zips: "340~09~96[2-6]",
              zip: "(\\d{5})(?:[ \\-](\\d{4}))?",
              zipex: "95014,22162-1010",
              format: [
                {
                  type: "A",
                  label: "Address line 1",
                  help: "APO, DPO, FPO address",
                  length: 1,
                  required: true,
                },
                {
                  type: "B",
                  label: "Address line 2",
                  help: "",
                  length: 1,
                  required: false,
                },
                {
                  type: "C",
                  label: "APO / DPO / FPO",
                  help: null,
                  length: 3,
                  required: true,
                },
                {
                  type: "S",
                  label: "AA / AE / AP",
                  help: null,
                  length: 3,
                  required: true,
                  options: [
                    {
                      name: "Armed Forces Americas (AA)",
                      key: "AA",
                      iso: null,
                      zipRegex: "340|96860|96863",
                      zipEx: "34000,96860,96863",
                      parentKey: "US",
                    },
                    {
                      name: "Armed Forces Europe (AE)",
                      key: "AE",
                      iso: null,
                      zipRegex: "09",
                      zipEx: "09000,09999",
                      parentKey: "US",
                    },
                    {
                      name: "Armed Forces Pacific (AP)",
                      key: "AP",
                      iso: null,
                      zipRegex: "96[2-6]",
                      zipEx: "96200,96699",
                      parentKey: "US",
                    },
                  ],
                },
                {
                  type: "Z",
                  label: "Zip",
                  help: null,
                  length: 3,
                  required: true,
                  example: "95014",
                  regex: "(\\d{5})(?:[ \\-](\\d{4}))?",
                },
              ],
              cfmt: "%N%n%A%n%B%n%C, %S %Z",
            }
          : Object.assign({}, this.postalDataForCountry(this.ctry));
      } else return null;
    },
    postalCodeExamples() {
      return this.getPostalCodeExamples(this.countryData, this.adr)
    },
    requiredParts() {
      return this.countryData && this.countryData.require
        ? this.countryData.require.split("").concat(["alt1", "countryiso"])
        : ["A", "C", "alt1", "countryiso"];
    },
    formatted() {
      if (this.countryFields && Array.isArray(this.countryFields)) {
        return this.countryFields
          .filter(({ type }) =>
            this.usesAlternateFormat
              ? /alt|country/.test(type)
              : /A|B|D|C|S|X|Z|country/.test(type)
          )
          .map((part) =>
            Object.assign({}, part, {
              messages:
                this.v && this.v[part.type].$error
                  ? Object.entries(this.v[part.type])
                      .filter(
                        ([key, value]) =>
                          key.charAt(0) !== "$" && value === false
                      )
                      .map(([k, v]) =>
                        this.getDictWP( this.getDictFLJ(`request.${this.fieldName}.messages.${part.type}-${k}`, this.dict),
                          {
                            label: this.localizeLabel(part.label),
                            zipExample: part.example,

                            /**
                             *  2024-04-03 John Yee
                             *  parameters for request.abr(or fwd)Adr.messages.Z-postalCode': butterDictionary.Q17
                             */
                            state: this.countryData.name+(this.adr.S ? ' '+this.adr.S : ''),
                            example: this.postalCodeExamples
                          }
                        )
                      )
                  : "",
              displayType:
                this.v && this.v[part.type].$error ? "is-danger" : "",
              class: {
                "is-required": this.requiredParts.includes(part.type),
                "is-optional": !this.requiredParts.includes(part.type),
                hide:
                  Boolean(this.adr && this.adr[part.type]) ||
                  (this.v && this.v[part.type].$error),
              },
            })
          );
      } else return [""];
    },
    adr: {
      get() {
        return this.getCurrent[this.fieldName] || {};
      },
      set(val) {
        this.update({
          [this.fieldName]: Object.assign({}, this.adr, val, {
            formatted: this.formattedAddress,
          }),
        });
      },
    },
    ...mapGetters("data", [
      "countriesWithPostalData",
      "postalMetadataHasCountry",
      "postalDataForCountry",
    ]),
    ...mapGetters("requests", ["getCurrent"]),
    ...mapGetters("userdata", ["userCountry"]),
    ...mapState("data", ["postal"]),
  },
  methods: {
    camelize(str) {
      return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, function (match, index) {
        if (+match === 0) return ""; // or if (/\s+/.test(match)) for white spaces
        return index === 0 ? match.toLowerCase() : match.toUpperCase();
      });
    },
    localizeLabel(label) {
      let placeholder = this.getDictFLJ(`addressPart.${this.camelize(label)}`, this.dict)
      return placeholder.includes("Error: Cannot resolve") ? label : placeholder
    },
    blurVfaCountrySelector(val) {
      /**
       * https://stackoverflow.com/questions/1096436/document-getelementbyidid-focus-is-not-working-for-firefox-or-chrome
       * 
       * The setTimeout suggestion works.
       * The this.$nextTick suggestion fails.
       */
      if (val==='noCountrySelected') {
        setTimeout(() => {
          this.focusCountry()
        }, 0)
      } else {
        this.updateAddress('countryiso', val)
      }
    },
    focusCountry() {
      this.$refs.ctry[0].$refs.input.focus()
    },
    closeAutocomplete() {
      this.$refs.A[0].isActive = false;
    },
    async getAsyncData(val) {
      await this.$nextTick();
      await this.$nextTick();
      placesAutocomplete.call(this, this.tempA, this.ctry, "abrAdr");
    },
    getPostalCodeExamples(countryAddressData, address) {
      /*
        2024-04-09 John Yee
        search for postal code examples
        search hierarchy - from general to specific i.e. from country to province
        specific examples override general examples

          country level
            zipex           <= look here first
            sub_zipex       <= look here second

          province level
            format type.S
              options
                province
                zipEx       <= province example
            format type.Z   <= generic "type Z" example

        if an example is null or empty,
        then ignore it (i.e. do not set the example to null or empty) and move down to the next level
      */

      let postalExamples = ''

      // country level examples
      if (countryAddressData.zipex) {
        postalExamples = countryAddressData.zipex
      }

      // province level examples that are declared as sub-examples
      if (countryAddressData.sub_zipexs) {
        /**
         * 2024-03-31 JY
         * sometimes the "province" is shown as the city so look for the city in the sub_names and sub_keys.
         * example: Andorra
         */
        let indexOfExample = -1

        if (countryAddressData.sub_names) {
          let arrayOfSubNames = []
          arrayOfSubNames = countryAddressData.sub_names.split('~')
          indexOfExample = arrayOfSubNames.findIndex(x => x === address.S || x === address.C)
        }

        if (indexOfExample<0 && countryAddressData.sub_keys) {
          let arrayOfSubKeys = []
          arrayOfSubKeys = countryAddressData.sub_keys.split('~')
          indexOfExample = arrayOfSubKeys.findIndex(x => x === address.S || x === address.C)
        }

        if (indexOfExample>-1) {
          postalExamples = countryAddressData.sub_zipexs.split('~')[indexOfExample]
        }
      }

      // province level examples that are declared in the specific province child objects
      if (countryAddressData.format) {
        const provinces = countryAddressData.format.find(x=>x.type==='S')
        if (provinces && provinces.options) {
          const province = provinces.options.find(x=>x.name===address.S || x.key===address.S)

          if (province && province.zipEx) {
            postalExamples = province.zipEx
          }
        } else {
          // no provinces; as a last attempt look for a postal code example in the generic type "Z" field
          const typeZ = countryAddressData.format.find(x=>x.type==='Z')
          if (typeZ && typeZ.example) {
            postalExamples = typeZ.example
          }
        }
      }

      return postalExamples ? '(e.g. '+postalExamples.split(',').join(' or ')+')' : ''
    },
    fillData(opt) {
      this.autocompleteFocused = false;
      placeDetails.call(this, opt);
    },
    createFormattedAddress() {
      this.countryFields = [1, 2, 3, 4, 5]
        .map((x) => ({
          help: "",
          label: `address line ${x}`,
          length: 1,
          required: x === 1,
          type: `alt${x}`,
        }))
        .concat({
          help: "",
          type: "countryiso",
          label: "Country",
          required: true,
          length: 1,
        })
        .concat(
          this.ctry && this.countryData
            ? this.countryData.format
            : [
                {
                  help: "",
                  label: "Street Address",
                  length: 1,
                  required: true,
                  type: "A",
                },
                {
                  help: "",
                  label: "Apartment",
                  length: 1,
                  required: false,
                  type: "B",
                },
                {
                  help: "",
                  label: "City",
                  length: 1,
                  required: true,
                  type: "C",
                },
                {
                  help: "",
                  label: "Province",
                  length: 1,
                  required: false,
                  type: "S",
                },
              ]
        );
    },
    updateAddress: async function (addressPart, value) {
      const valueTrimmed = value ? value.trim().replace(/\s+/g, ' ') : value
      let cleanAdr =
        !this.countryData || !this.formatted || !this.adr
          ? this.adr
          : Object.entries(this.adr).reduce((obj, [k, v]) => {
              if (
                this.countryFields
                  .concat({ type: "usesAlternateFormat" })
                  .map(({ type }) => type)
                  .includes(k)
              ) {
                obj[k] = v;
              }
              return obj;
            }, {});
      await this.update({
        [this.fieldName]: Object.assign({}, cleanAdr, {
          [addressPart]: cleanString(valueTrimmed) || null,
          formatted: this.formattedAddress,
        }),
      });
      this.$emit("delayTouch", addressPart);
    },
    getDictFLJ(dictItem, dict) {
      /**
       * 2022-12-04 John Yee
       * This construction looks weird - like a recursive call; but, it's not.
       * The "getDictFLJ(dictItem, dict)" in the return statement is really
       * the function getDictFLJ(dictItem, dict) that is imported from ~/utils/butterUtils.js
       * 
       * reference: https://stackoverflow.com/questions/52332993/calling-a-function-from-a-helper-in-vue-template
       */
      return getDictFLJ(dictItem, dict)
    },
    getDictWP(dictItem, paramObj) {
      /**
       * 2022-12-04 John Yee
       * This construction looks weird - like a recursive call; but, it's not.
       * The "getDictWP(dictItem, paramObj)" in the return statement is really
       * the function getDictWP that is imported from ~/utils/butterUtils.js
       * 
       * reference: https://stackoverflow.com/questions/52332993/calling-a-function-from-a-helper-in-vue-template
       */
      return getDictWP(dictItem, paramObj)
    },
    ...mapActions("data", ["updateCountryData"]),
    ...mapMutations("requests", ["update"]),
  },
  watch: {
    postal: function (val) {
      this.createFormattedAddress();
    },
    ctry: async function (val, oldVal) {
      if (val) {
        if (this.postalMetadataHasCountry(val)) {
          this.createFormattedAddress();
        } else {
          await this.updateCountryData(val);
          // await this.$nextTick()
          this.createFormattedAddress();
        }
      }
    },
    userCountry(val) {
      if (!this.ctry && val) {
        this.updateAddress("countryiso", val);
      }
    },
  },
  mounted: async function () {
    this.sessionToken = uuidv4();
    if (this.ctry) {
      await this.updateCountryData(this.ctry);
      await this.$nextTick();
      this.createFormattedAddress();
    }
    if (!this.ctry && this.userCountry) {
      this.updateAddress("countryiso", this.userCountry);
      await this.updateCountryData(this.userCountry);
      await this.$nextTick();
      this.createFormattedAddress();
    }
    if (process.browser) {
      // window.onNuxtReady(() => {
      this.isInfoOpen = true;
      setTimeout(() => {
        this.isInfoOpen = false;
      }, 10);
      // })
    }
    if (this.adr.A) this.tempA = this.adr.A || "";
    //   })
    // }
  },
  beforeDestroy: async function () {
    await this.updateCountryData(this.ctry);
    await this.$nextTick();
    this.createFormattedAddress();
    let cleanAdr =
      !this.countryData || !this.formatted || !this.adr
        ? this.adr
        : Object.entries(this.adr).reduce((obj, [k, v]) => {
            if (
              this.countryFields
                .concat({ type: "usesAlternateFormat" })
                .map(({ type }) => type)
                .includes(k)
            ) {
              obj[k] = v && typeof v === "string" ? v.trim().replace(/\s+/g, ' '): v;
            }
            return obj;
          }, {});
    this.update({ [this.fieldName]: Object.assign({}, cleanAdr, { formatted: this.formattedAddress }) });
  },
};
